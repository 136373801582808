@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  *{
    @apply ease-in-out duration-300 transition-all scroll-smooth;
  }
  h1{
    @apply text-4xl md:text-6xl xl:text-8xl font-light uppercase;
  }
  h2{
    @apply  text-2xl xl:text-5xl font-light uppercase;
  }
  h3{
      @apply text-2xl xl:text-3xl font-bold uppercase;
  }
  h4{
    @apply text-lg xl:text-2xl font-semibold uppercase;
  }
  .sub-menu{
    @apply  md:justify-between lg:justify-start
  }
  li{
    @apply  relative overflow-hidden
  }
  .sub-menu > li{
    @apply relative overflow-hidden  md:text-[12px] lg:text-sm xl:text-base
  }
  a > div{
    @apply border-b bg-primary-500 -translate-x-full
  }
  li:hover > a>div{
    @apply border-b translate-x-0
  }
}

  .scroll-to-top {
    @apply fixed bottom-8 right-8 hidden hover:-translate-y-0.5
  }

  .scroll-to-top.show {
    display: block;
  }



  @media screen and (max-height: 800px) {
    .resizeAnimation  {
      animation: zoom-in-animation 0.7s ease-in-out;
    }
  }

  @keyframes zoom-in-animation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  
  
  
  
  